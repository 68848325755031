import React from "react";

import BookDemoForm from "../components/bookDemo/form";
import useTranslations from "../components/useTranslations";
import HelmetHeader from "../components/helmetHeader";
import { graphql } from "gatsby";

const BookDemo = ({ location, data: { page } }) => {
  const { lang } = useTranslations();

  return (
    <div className="bg-white">
      <HelmetHeader
        location={location}
        lang={lang}
        title={page.seo?.metaTitle}
        metas={[{ name: "description", content: page.seo?.metaDescription }]}
      />
      <div className="w-full bg-white">
        <BookDemoForm logoZone={page.logoZone} form={page.form} />
      </div>
    </div>
  );
};

export const query = graphql`
  query StrapiBookDemoPage($locale: String) {
    page: strapiBookDemoPage(locale: { eq: $locale }) {
      seo {
        metaDescription
        metaTitle
      }
      form {
        title
        buttonValid
        strapi_fields {
          fieldId
          placeholder
          order
          label
          type
          errorMessage
        }
        successTitle
        successDescription
        successLink {
          label
          href
          target
        }
      }
      logoZone {
        title
        description
        logos {
          file {
            alternativeText
            url
          }
          order
        }
        features {
          text
        }
      }
    }
  }
`;

export default BookDemo;
