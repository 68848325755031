import React, { Fragment, useMemo, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import useTranslations from "../useTranslations";
import { sendEvent } from "../../utils/googleMeasurementProtocol";
import { HiCheck, HiX } from "react-icons/hi";
import { Transition } from "@headlessui/react";

export default function BookDemoForm({ form, logoZone }) {
  const [isSent, setIsSent] = useState(false);
  const { bookDemo } = useTranslations();

  const Schema = useMemo(
    () =>
      Yup.object().shape({
        fullName: Yup.string().required(bookDemo.form.requiredField),
        job: Yup.string().required(bookDemo.form.requiredField),
        company: Yup.string().required(bookDemo.form.requiredField),
        email: Yup.string()
          .email(bookDemo.form.invalidEmail)
          .required(bookDemo.form.requiredField),
      }),
    [bookDemo]
  );
  return (
    <div>
      <div className="max-w-5xl mx-auto pb-16 px-4 sm:px-8 lg:px-0 pt-12 lg:pt-16 ">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-16 gap-x-24 items-start">
          <div className="col-span-1">
            <Formik
              enableReinitialize={true}
              initialValues={{
                fullName: "",
                email: "",
                company: "",
                job: "",
              }}
              validationSchema={Schema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                window.dataLayer.push({
                  event: "book_demo",
                  user_data: {
                    address: {
                      first_name: values.fullName,
                    },
                    email_address: values.email,
                  },
                  company: values.company,
                  job: values.job,
                });
                setSubmitting(false);
                setIsSent(true);
                resetForm();
              }}
            >
              {({ values, isSubmitting, setFieldValue, errors, touched }) => (
                <Form className="flex flex-col gap-4">
                  <h3 className="mb-1 text-xl font-medium text-gray-900 sm:text-3xl sm:tracking-tight lg:text-2xl">
                    {form?.title}
                  </h3>
                  {form?.strapi_fields?.map((field) => (
                    <div>
                      <div className="mt-1">
                        <input
                          type={field.type}
                          name={field.fieldId}
                          id={field.fieldId}
                          className={`bg-gray-100 focus:ring-cyan-500 focus:border-cyan-500 block w-full sm:text-md h-12 py-7 pl-5 rounded-md ${
                            errors[field.fieldId] && touched[field.fieldId]
                              ? "border-red-500"
                              : "border-none"
                          }`}
                          value={values[field.fieldId]}
                          onChange={(e) =>
                            setFieldValue(field.fieldId, e.target.value)
                          }
                          placeholder={field.placeholder}
                        />
                      </div>
                      {errors[field.fieldId] && touched[field.fieldId] && (
                        <p className="mt-1 text-sm text-red-600">
                          {field.errorMessage}
                        </p>
                      )}
                    </div>
                  ))}

                  <div>
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className="ml-auto w-full mt-4 block rounded-md border border-transparent px-5 py-3 bg-cyan-600 text-base font-medium text-white shadow hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-cyan-700 sm:px-10 disabled:opacity-50 "
                    >
                      {form.buttonValid}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="col-span-1 flex flex-col gap-3">
            <h3 className="text-2xl font-medium text-gray-900">
              {logoZone?.title}
            </h3>
            <p className="mt-3 max-w-3xl text-base text-gray-500">
              {logoZone?.description}
            </p>
            <ul className="mt-1">
              {logoZone?.features?.map((feature) => (
                <li>
                  <div className="flex items-center gap-3">
                    <HiCheck className="text-green-700 w-5 h-5" />
                    <span className="text-base text-gray-500">
                      {feature.text}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
            <div className="mt-5 pt-5 border-t grid grid-cols-3 gap-y-2">
              {logoZone?.logos?.map((logo) => (
                <div
                  key={logo.order}
                  className="flex justify-center items-center px-5 py-1"
                >
                  <img
                    className="w-full"
                    src={logo.file?.url}
                    alt={logo.file?.alternativeText}
                    placeholder="blurred"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Transition.Root show={isSent} as={Fragment}>
        <div as="div" className="relative z-10" onClose={setIsSent}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div className="absolute top-0 right-0 pt-4 pr-4 block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setIsSent(false)}
                    >
                      <span className="sr-only">Close</span>
                      <HiX className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <HiCheck
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <div
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        {form?.successTitle}
                      </div>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          {form?.successDescription}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <a
                      href={form?.successLink?.href}
                      target={form?.successLink?.target}
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none sm:text-sm"
                    >
                      {form?.successLink?.label}
                    </a>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Transition.Root>
    </div>
  );
}
